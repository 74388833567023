import {createSlice, Dispatch} from '@reduxjs/toolkit';
import {SelectedFlightService} from "../actions";
import {dailyFlights, setLoading} from "./daliyFlights";
import {addToLSObject} from "../utils";

export const setDepartureFlight = (body: any) => async (dispatch: Dispatch) => {
    dispatch(departureFlight(body));
}

export const setReturnFlight = (body: any) => async (dispatch: Dispatch) => {
    dispatch(returnFlight(body));
}

export const clearFlights = () => (dispatch: Dispatch) => {
    dispatch(departureFlight(null));
    dispatch(returnFlight(null));
}

export const fetchPricePreview = (body: any) => async (dispatch: Dispatch) => {
    setLoading(true);
    const response = await SelectedFlightService.previewPrice(body);
    dispatch(setPricePreview(response));
    dispatch(setAdultPrice(response));
    dispatch(setChildPrice(response));
    dispatch(setInfantPrice(response));
    setLoading(false);
}

interface SelectedFlightSlice {
    departureFlight: any;
    returnFlight: any;
    pricePreview: number | any;
    priceAdult: number | any;
    priceChild: number | any;
    priceInfant: number | any;
}

const initialState = (): SelectedFlightSlice => {
    let localStorageData;

    try {
        localStorageData = JSON.parse((window as any).localStorage.getItem("pr_jet_flights_data"));
    } catch (error) {
        localStorageData = null;
        console.warn('There was a problem with LS', error)
    }

    return  {
        departureFlight: null,
        returnFlight: null,
        pricePreview: 0,
        priceAdult: 0,
        priceChild: 0,
        priceInfant: 0,
        ...localStorageData
    }
}

const stateSlice = createSlice({
    name: 'bookflight/selected-flight',
    initialState: initialState(),
    reducers: {
        departureFlight: (state: any, action) => {
            addToLSObject("pr_jet_flights_data", "departureFlight", action.payload);
            state.departureFlight = action.payload
        },
        returnFlight: (state: any, action) => {
            addToLSObject("pr_jet_flights_data", "returnFlight", action.payload);
            state.returnFlight = action.payload
        },
        setPricePreview: (state: any, action) => {
            state.pricePreview = (action.payload.reservationTotalPrice + action.payload.totalTaxes) + `  ${action.payload.currencyResponse.symbol}`;
        },
        setAdultPrice: (state: any, action) => {
            state.priceAdult = (action.payload.departureAdultPriceForOne) + `${action.payload.currencyResponse.symbol}`;
            if (state.returnFlight) {
                state.priceAdult = (action.payload.departureAdultPriceForOne + action.payload.returnAdultPriceForOne) + `  ${action.payload.currencyResponse.symbol}`;
            }
        },
        setChildPrice: (state: any, action) => {
            state.priceChild = (action.payload.departureChildPriceForOne) + `${action.payload.currencyResponse.symbol}`;
            if (state.returnFlight) {
                state.priceChild = (action.payload.departureChildPriceForOne + action.payload.returnChildPriceForOne) + `  ${action.payload.currencyResponse.symbol}`;
            }
        },
        setInfantPrice: (state: any, action) => {
            state.priceInfant = (action.payload.departureInfantPriceForOne) + `${action.payload.currencyResponse.symbol}`;
            if (state.returnFlight) {
                state.priceInfant = (action.payload.departureInfantPriceForOne + action.payload.returnInfantPriceForOne) + `  ${action.payload.currencyResponse.symbol}`;
            }
        }
    }
});

export const {departureFlight, returnFlight, setPricePreview, setAdultPrice, setChildPrice, setInfantPrice} = stateSlice.actions;

export default stateSlice.reducer;
