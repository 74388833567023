import React, {useState} from 'react'
import styles from "./Checkin.module.scss";
import Star from "../../assets/images/Group 2.svg"
import Ticket from "../../assets/images/Group 3.svg"
import Seat from "../../assets/images/Group 4.svg"
import Headphones from "../../assets/images/Group 5.svg"
import {useTranslation} from "react-i18next";

const Checkin = () => {

  const {t} = useTranslation();

  return (
      <div className={styles.benefits}>
        <h1>{t("app.benefits.title" , "Blerje e Sigurt dhe e Shpejtë Online me AirTiketa.com")}</h1>
        <div className={styles.benefitsContent}>
          <div className={styles.benefitsRow}>
          <img src={Star}/>
          <div className={styles.benefitsText}>
            <h3>{t("app.benefits.subTitle1" , "Fluturo Lirë dhe 100% Sigurtë")}</h3>
            <p>{t("app.benefits.paragraph1" , "Rezervoni Fluturimin Tuaj Online - Pagesë të Sigurt!")}</p>
          </div>
        </div>
          <div className={styles.benefitsRow}>
            <img src={Seat}/>
            <div className={styles.benefitsText}>
              <h3>{t("app.benefits.subTitle2" , "Zgjedhja e Ulëses Falas")}</h3>
              <p>{t("app.benefits.paragraph2" , "Zgjidhni ulëset pa kosto shtesë dhe udhëtoni rehat!")}</p>
            </div>
          </div>
          <div className={styles.benefitsRow}>
            <img src={Ticket}/>
            <div className={styles.benefitsText}>
              <h3>{t("app.benefits.subTitle3" , "Check-in Falas në Aeroport")}</h3>
              <p>{t("app.benefits.paragraph3" , "Check-in falas në aeroport - udhëtoni pa pengesa!")}</p>
            </div>
          </div>
          <div className={styles.benefitsRow}>
            <img src={Headphones}/>
            <div className={styles.benefitsText}>
              <h3>{t("app.benefits.subTitle4" , "Mbështetje në Çdo Moment për Udhëtarët Tanë")}</h3>
              <p>{t("app.benefits.paragraph4" , "Qendra jonë e kontaktit: 24/7 në Gjermani, Zvicër, dhe Kosovë.")}</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Checkin;