import React from 'react';
import styles from './Header.module.scss';
import SwissFlag from "../../assets/images/switzerland (1).png";
import GermanyFlag from "../../assets/images/germany (1).png";
import KosovaFlag from "../../assets/images/kosovo (1).png";

const isMobile = window.innerWidth < 768;

const PhoneNumbersHeader = () => {

  return (
      <div className={styles.phoneHeader}>
        <div className={styles.phoneHeaderContent}>
          {!isMobile ?
              <>
                <img src={SwissFlag} alt={"Swiss Flag"}/>
                <a href="tel: +41 44 586 36 66">CH: +41 44 586 36 66</a>
              </>

              :
              <>
                <img src={SwissFlag} alt={"Swiss Flag"}/>
                <a href="tel: +41 44 586 36 66">+41445863666</a>
              </>
          }
        </div>
        <div className={styles.phoneHeaderContent}>
          {!isMobile ?
              <>
                <img src={GermanyFlag} alt={"Germany Flag"}/>
                <a href="tel: +49 211 38533933">DE: +49 211 38533933</a>
              </>
              :
              <>
                <img src={GermanyFlag} alt={"Germany Flag"}/>
                <a href="tel: +49 211 38533933">+4921138533933</a>
              </>
          }
        </div>
        <div className={styles.phoneHeaderContent}>
          {!isMobile ?
              <>
                <img src={KosovaFlag} alt={"Kosova Flag"}/>
                <a href="tel: +383 48 282 284">KS: +383 48 282 284</a>
              </>
            :
              <>
                <img src={KosovaFlag} alt={"Kosova Flag"}/>
                <a href="tel: +383 48 282 284">+38348282284</a>
              </>
          }
        </div>
      </div>
  )
}

export default PhoneNumbersHeader;