import React, {useEffect, useState} from 'react';
import styles from './Footer.module.scss';
import {Col, Divider, Row} from 'antd';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {FacebookFilled, InstagramFilled, LinkedinFilled, TwitterSquareFilled} from "@ant-design/icons";
import Logo from "../../assets/images/white-airtiketa-logo.png";
import ApplePay from "../../assets/images/card_square_apple-pay-600px.png";
import GiroPay from "../../assets/images/card_square_giropay-600px.png";
import GooglePay from "../../assets/images/card_square_google-pay-600px.png";
import MasterCard from "../../assets/images/card_square_mastercard.png";
import PostFinance from "../../assets/images/card_square_postfinance.png";
import SamsungPay from "../../assets/images/card_square_samsung-pay.png";
import Twint from "../../assets/images/card_square_twint.png";
import Visa from "../../assets/images/card_square_visa_inverted.png";
import TermsConditions from "../../containers/BookFlight/Overview/components/Terms&Conditions";
import PrivacyPolicy from "../../containers/BookFlight/Overview/components/Privacy&Policy";
import {Link} from "react-router-dom";

const Footer = (props: any) => {

  const isMobile = window.innerWidth < 900;

  const history = useHistory();
  const {t} = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false);

  const facebookUrl = "https://www.facebook.com/airtiketa";
  const instagraUrl = "https://www.instagram.com/airtiketa/";
  const twitterUrl = "https://twitter.com/airtiketa";

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setIsPrivacyModalVisible(false);
  };

  const showPrivacyModal = () => {
    setIsPrivacyModalVisible(true);
  };

  return (
      <div className={styles.footer}>
        <div className={styles.footerMain}>
          <div className="footerContent">
            <img width={210} src={Logo}/>
            <p style={{marginTop: "35px"}}>
              Airtiketa GmbH
              <br/>
              Colmarer Str. 1
              <br/>
              9576 Weil am Rhein
              <br/>
              {t("app.footer.address", "Germany")}
            </p>
            <div style={
              {
                display: "flex",
                justifyContent: "space-between",
                width: "100px",
                fontSize: "21px",
                color: "white"
              }}>
              <a href={facebookUrl} target="_blank" rel="noopener noreferrer"> <FacebookFilled
                  style={{cursor: 'pointer', color: 'white'}}/></a>
              <a href={instagraUrl} target="_blank" rel="noopener noreferrer"> <InstagramFilled
                  style={{cursor: 'pointer', color: 'white'}}/></a>
              <a href={twitterUrl} target="_blank" rel="noopener noreferrer"> <TwitterSquareFilled
                  style={{cursor: 'pointer', color: 'white'}}/></a>
            </div>
            <p style={{fontSize: '10px'}}>{t("app.footer.copyRight", "Të gjitha të drejtat e rezervuara Airtiketa GmbH 2024")}</p>
          </div>
          {!isMobile && (
              <div className="footerPadding">
                <ul>
                  <li><h3><b>{t("app.footer.paymentMethods", "Mënyrat e pagesës:")}</b></h3></li>
                  <img src={ApplePay} alt={"Apple Pay"} width={"30px"}/>
                  <img src={GiroPay} alt={"Apple Pay"} width={"30px"}/>
                  <img src={GooglePay} alt={"Apple Pay"} width={"30px"}/>
                  <img src={MasterCard} alt={"Apple Pay"} width={"30px"}/>
                  <img src={PostFinance} alt={"Apple Pay"} width={"30px"}/>
                  <img src={SamsungPay} alt={"Apple Pay"} width={"30px"}/>
                  <img src={Twint} alt={"Apple Pay"} width={"30px"}/>
                  <img src={Visa} alt={"Apple Pay"} width={"30px"}/>
                </ul>
              </div>
          )}
          <div className="footerPadding">
            <ul>
              <li><h3><b>{t("app.footer.information", "Informacioni:")}</b></h3></li>
              <li>{t("app.footer.info.documentation", "Dokumentet")}</li>
              <li>{t("app.footer.info.1", "Udhëtimi me fëmijë dhe të mitur")}</li>
              <li>{t("app.footer.info.2", "Pasagjerë me nevoja të veçanta")}</li>
              <li>{t("app.footer.info.3", "Udhëtimi për shtatzëna")}</li>
              <li>{t("app.footer.info.4", "Udhëtimi me kafshë shtëpiake")}</li>
              <li>{t("app.footer.info.5", "Information i Check-in")}</li>
              <li>{t("app.footer.info.6", "Mënyrat e pagesës")}</li>
              <li><Link to="/terms&conditions">{t("app.footer.info.7", "Kushtet e Shërbimit")}</Link></li>
              <li><Link to="/privacy-policy">{t("app.footer.info.8", "Politika e Privatësisë")}</Link></li>
            </ul>
          </div>
          {!isMobile && (
              <div>
                <ul>
                  <li><h3><b>{t("app.footer.baggage", "Bagazhi: ")}</b></h3></li>
                  <li>{t("app.footer.mainBaggage", "Bagazhi kryesor")}</li>
                  <li>{t("app.footer.handLuggage", "Bagazhi i dorës")}</li>
                  <li>{t("app.footer.sportBaggage", "Pajisje sportive")}</li>
                  <li>{t("app.footer.musicalBaggage", "Instrumente muzikore")}</li>
                  <li>{t("app.footer.addedBaggage", "Tarifa shtesë")}</li>
                </ul>
              </div>
          )}
        </div>
      </div>
  )
}
export default Footer;
