import React, { useState } from 'react';
import styles from './FrequantlyAskedQuestions.module.scss';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [activeDropdown, setActiveDropdown] = useState<string | null>('beforeFlight');

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const beforeFlight = (
      <Menu>
        <Collapse
            defaultActiveKey={['1']}
            onChange={onChange}
            expandIcon={({ isActive }) => (
                <PlusOutlined style={{ color: '#DB0001', fontSize: '16px' }} rotate={isActive ? 45 : 0} />
            )}
        >
          <Panel header={t('app.home.FAQ.beforeFlight.question1', 'How can I prepare for the flight?')} key="1">
            <p>{t('app.home.FAQ.beforeFlight.answer1', 'We recommend arriving at least two hours before your flight. Check-in closes 40 minutes before departure.')}</p>
          </Panel>
          <Panel header={t('app.home.FAQ.beforeFlight.question2', 'What are the necessary documents for travel?')} key="2">
            <p>{t('app.home.FAQ.beforeFlight.answer2', 'Valid passport and, if necessary, valid visa. For security, it is recommended to also carry your passport on all flights.')}</p>
          </Panel>
          <Panel
              header={t('app.home.FAQ.beforeFlight.question3', 'How do I book a trip for an unaccompanied child?')}
              key="3"
          >
            <p>{t('app.home.FAQ.beforeFlight.answer3', 'Unaccompanied children cannot be booked online. For such trips, please contact us directly.')}</p>
          </Panel>
          <Panel header={t('app.home.FAQ.beforeFlight.question4', 'What are the rules for unaccompanied children?')} key="4">
            <p>{t('app.home.FAQ.beforeFlight.answer4', 'We have special rules for children between 5 and 11 years old, and children between 3 and 5 years old. Hello 12-15 year old companion.')}</p>
          </Panel>
        </Collapse>
      </Menu>
  );

  const checkin = (
      <Menu>
        <Collapse
            onChange={onChange}
            expandIcon={({ isActive }) => (
                <PlusOutlined style={{ color: '#DB0001', fontSize: '16px' }} rotate={isActive ? 45 : 0} />
            )}
        >
          <Panel
              header={t('app.home.checkin.specialNeeds.question', 'How are special needs passengers handled at the airport and on board?')}
              key="1"
          >
            <p>{t('app.home.checkin.specialNeeds.answer', 'We provide services and assistance for special needs passengers. Please check in 120 minutes before your flight for tailored care.')}</p>
          </Panel>
          <Panel
              header={t('app.home.checkin.documentation.question', 'What are the necessary documents and conditions for travel?')}
              key="2"
          >
            <p>{t('app.home.checkin.documentation.answer', 'Valid passport and, if applicable, a valid visa. Travel with invalid documents is not allowed, and passengers with photocopied documents are not permitted.')}</p>
          </Panel>
        </Collapse>
      </Menu>
  );

  const luggage = (
      <Menu>
        <Collapse
            onChange={onChange}
            expandIcon={({ isActive }) => (
                <PlusOutlined style={{ color: '#DB0001', fontSize: '16px' }} rotate={isActive ? 45 : 0} />
            )}
        >
          <Panel header={t('app.home.luggage.question1', 'How much luggage can I carry on the flight?')} key="1">
            <p>{t('app.home.luggage.answer1', 'Each person is entitled to 20 kg of luggage and 5 kg of hand luggage. For extra luggage, you pay 8 EUR for 1 kg.')}</p>
          </Panel>
          <Panel header={t('app.home.luggage.question2', 'What types of luggage are not allowed?')} key="2">
            <p>{t('app.home.luggage.answer2', 'Luggage with vegetables, firearms, meat, and explosive and liquid items is not allowed.')}</p>
          </Panel>
          <Panel
              header={t('app.home.luggage.animals.question', 'What are the conditions for passengers traveling with animals?')}
              key="3"
          >
            <p>{t('app.home.luggage.animals.answer', 'Passengers with animals must have a passport, a veterinarian certificate, and a cage that meets European standards.')}</p>
          </Panel>
          <Panel
              header={t('app.home.luggage.moreInfo', 'This is a brief overview of common questions. For more detailed information, please contact us.')}
              key="4"
          />
        </Collapse>
      </Menu>
  );

  return (
      <div className={styles.faqComponent}>
        <h1>{t('app.home.FAQ.title', 'Frequently Asked Questions')}</h1>
        <div className={styles.dropdowns}>
          <Dropdown
              overlayStyle={{
                minWidth: '100%',
                position: 'absolute',
                zIndex: 1,
              }}
              overlay={beforeFlight}
              trigger={['click']}
              onVisibleChange={(visible) => setActiveDropdown(visible ? 'beforeFlight' : null)}
              visible={activeDropdown === 'beforeFlight'}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space className={styles.space}>
                {t('app.home.FAQ.beforeFlight', 'Before flight')}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <Dropdown
              overlayStyle={{
                minWidth: '100%',
                position: 'absolute',
                zIndex: 1,
              }}
              overlay={checkin}
              trigger={['click']}
              onVisibleChange={(visible) => setActiveDropdown(visible ? 'checkin' : null)}
              visible={activeDropdown === 'checkin'}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space className={styles.space}>
                {t('app.home.FAQ.checkin', 'Check in')}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <Dropdown
              overlayStyle={{
                minWidth: '100%',
                position: 'absolute',
                zIndex: 1,
              }}
              overlay={luggage}
              trigger={['click']}
              onVisibleChange={(visible) => setActiveDropdown(visible ? 'luggage' : null)}
              visible={activeDropdown === 'luggage'}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space className={styles.space}>
                {t('app.home.FAQ.luggage', 'Luggage')}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
  );
};

export default FrequentlyAskedQuestions;
