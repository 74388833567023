import React from "react";
import styles from "./BookingDetailsPassenger.module.scss";
import {ReactComponent as Arrow} from "../../assets/images/arrow-up.svg";
import {PassengerType} from "../PassengerFormRow/PassengerFormRow";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

interface Props {
    passenger: any;
    index: number;
    expanded: boolean;
    onClick: any;
}

const BookingDetailsPassenger = ({passenger, index, expanded, onClick}: Props) => {
  const { t } = useTranslation();

  const type = passenger.ageCategory == "INF" ? "INFANT" : passenger.ageCategory;

  // const PassengerTitle: any = {
  //   [PassengerType.ADULT]: t("app.bookFlight.passengers.passengerRow.adult", "Adult"),
  //   [PassengerType.CHILD]: t("app.bookFlight.passengers.passengerRow.child", "Child"),
  //   [PassengerType.INFANT]: t("app.bookFlight.passengers.passengerRow.baby", "Infant")
  // }
    return (
        <div className={styles.passenger} onClick={onClick}>
            <div className={styles.content}>
                <div className={styles.subtitle}>
                    Passenger {index + 1} - {type}
                </div>
                <div className={styles.title}>
                    {passenger.name} {passenger.surname}
                </div>
            </div>
            <div className={classNames(styles.arrow, {[styles.rotate]: expanded})}>
                <Arrow/>
            </div>
        </div>
    )
}

export default BookingDetailsPassenger;
