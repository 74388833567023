import React from "react";
import styles from './Passenger.module.scss';
import {useTranslation} from "react-i18next";
import {PassengerType} from "../PassengerFormRow/PassengerFormRow";
import {useSelector} from "react-redux";
import {AppState} from "../../store";

interface Props {
  passenger: any;
}

const Passenger = ({passenger}: Props) => {
  const {t} = useTranslation();

  const adultPrice = useSelector((state: AppState) => state.selectedFlights.priceAdult);
  const childPrice = useSelector((state: AppState) => state.selectedFlights.priceChild);
  const infantPrice = useSelector((state: AppState) => state.selectedFlights.priceInfant);

  const type = passenger.ageCategory;

  const PassengerTitle: any = {
    [PassengerType.ADULT]: t("app.bookFlight.index.adult.lowercase", "Adult"),
    [PassengerType.CHILD]: t("app.bookFlight.index.child.lowercase", "Child"),
    [PassengerType.INFANT]: t("app.bookFlight.index.infant.lowercase", "Infant"),
  };

  let price;
  switch (type) {
    case PassengerType.ADULT:
      price = adultPrice;
      break;
    case PassengerType.CHILD:
      price = childPrice;
      break;
    case PassengerType.INFANT:
      price = infantPrice;
      break;
    default:
      price = 0;
  }

  return (
      <div className={styles.passengerDetails}>
        <p>{t("app.bookFlight.overview.bookingInformation", "Passenger ")}</p>
        <p style={{color: "#DB0001"}}>{passenger.name} {passenger.surname}</p>
        <p style={{color: "#DB0001"}}>{PassengerTitle[type]}</p>
        <p style={{color: "#DB0001"}}>{price}</p>
      </div>
  )
}

export default Passenger;
