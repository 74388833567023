import React, { useEffect, useState } from 'react';
import styles from "./FlightBox.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import 'moment/locale/de';  // Import German locale
import 'moment/locale/fr';  // Import French locale
import 'moment/locale/sq';  // Import Albanian locale (make sure it's available in moment)

interface Props {
    flight: any;
    className?: string;
    selected?: boolean;
    disabled?: boolean;
    onClick: any;
}

const FlightBox = (props: Props) => {
    const { t, i18n } = useTranslation();
    const { flight, selected, disabled, className, onClick } = props;

    const [locale, setLocale] = useState(i18n.language);

    useEffect(() => {
        moment.locale(i18n.language);
        setLocale(i18n.language); // Force a re-render to apply the new locale
    }, [i18n.language]);

    const formatPrice = (price: any) => {
        return price.replace('.', ',');
    };

    const classes = classNames(styles.flightBox, className, {
        [styles.disabled]: disabled,
        [styles.selected]: selected,
    });

    return (
        <div className={classes} onClick={onClick}>
            <div className={styles.day}>{moment(flight.dateOfDeparture, "DD/MM/YYYY").format("dddd")}</div>
            <div className={styles.date}>{moment(flight.dateOfDeparture, "DD/MM/YYYY").format("D MMMM")}</div>
            <div className={styles.price}>{flight.lowestPrice ? formatPrice(flight.lowestPrice) : <p>{t("app.flightBox.noFlight", "Nuk ka fluturim")}</p>}</div>
        </div>
    );
}

export default FlightBox;
