import React from "react";
import {useTranslation} from "react-i18next";
import {ArrowForward} from "@material-ui/icons";
import Footer from "../../components/Footer/Footer";

const Information = () => {
  const {t} = useTranslation();
  return (
      <div>
        Test
        <Footer/>
      </div>
  )
}

export default Information;
