import React, {useEffect} from 'react'
import "./RangePickerPanel.scss";
import {ReactComponent as CloseIcon} from "../../assets/images/close.svg";
import {useTranslation} from "react-i18next";

interface Props {
    panel: any;
    departureDate: any;
    returnDate: any;
    onSaveButtonClick: any;
    onCancelButtonClick: any;
    onOneWayButtonClick: any;
    activeInput: string;
}

const RangePickerPanel = ({panel, departureDate, onOneWayButtonClick, onCancelButtonClick, onSaveButtonClick, activeInput}: Props) => {
    const {t} = useTranslation();
    return (
        <div className='range-picker-panel-container'>
            <div className="select-input-header"
                 onClick={onCancelButtonClick}>
                <div className="select-input-header-title">
                    {activeInput === "departure"
                        ? t("app.home.form.departure.mobile.header", "Choose Departure Date")
                        : t("app.home.form.return.mobile.header", "Choose Return Date") }
                </div>
                <CloseIcon/>
            </div>
            <div className="range-picker-panel-header">
                <div className="header">
                    <div className="range-picker-panel-header-title">
                        {departureDate != null ? <span>{t("app.homepageForm.calendarTitle.chooseReturn", "Choose your return time")}</span> : <span>{t("app.homepageForm.calendarTitle.chooseDeparture", "Choose your departure time")}</span>}
                    </div>
                    {/*<button className="home-page-popup-button home-page-popup-primary-button"*/}
                    {/*        onClick={onOneWayButtonClick}>*/}
                    {/*    {t("app.home.form.rangePicker.oneWay.button", "One way ticket") }*/}
                    {/*</button>*/}
                </div>
            </div>
            {panel}
            <div className="range-picker-panel-footer">
                <button className="home-page-popup-button home-page-popup-secondary-button mr-4"
                        onClick={onCancelButtonClick}>
                    {t("app.home.form.rangePicker.cancel.button", "Cancel") }
                </button>
                <button className="home-page-popup-button home-page-popup-primary-button"
                        onClick={onSaveButtonClick}>
                    {t("app.home.form.rangePicker.save.button", "Save") }
                </button>
            </div>
        </div>
    )
}

export default RangePickerPanel;
