import {ManualPayment} from "../ManualPayments";
import {useEffect} from "react";
import printJS from "print-js";

type iProps = {
  payment: ManualPayment
  confirmationCode?: number
  debt?: string
  showPrint: any
}

const PrintPaymentMethod = ({payment, showPrint, confirmationCode, debt}: iProps) => {

  useEffect(() => {
    printJS({
      printable: 'print-payment',
      type: 'html',
      documentTitle: 'Airtiketa',
      targetStyles: ['*'],
    });
    showPrint(false);
  }, []);

  return (
      <div id="print-payment">
        <p>Pagesa {payment.country}</p>
        <p>Branch name: <span>{payment.name}</span></p>
        {payment.description && (
            <p>{payment.description}</p>
        )}
        {payment.address && (
            <p>Address: <span>{payment.address}</span></p>
        )}
        <p>Swift code: <span>{payment.bicSwift}</span></p>
        <p>Account Number: <span>{payment.IBAN}</span></p>
        {confirmationCode && (<p>Confirmation Code: <span>{confirmationCode}</span></p>)}
        {debt && (<p>Total amount: <span>{debt}</span></p>)}
      </div>
  )
}

export default PrintPaymentMethod;