import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppState } from "../../store";
import { resetHomeForm } from "../../store/home";
import { clearPassengers } from "../../store/passengers";
import { clearFlights } from "../../store/selectedFlights";
import { Button } from "antd";
import React from "react";
import Person from "../../assets/images/person-red.svg";
import { PassengerType } from "../../components/PassengerFormRow/PassengerFormRow";

export interface BookFlightFooterProps {
  continue?: () => void;
  hideContinue?: boolean;
  disableContinue?: boolean;
  newSearchBtnHide?: boolean;
  totalPrice?: string | number;
}

const BookFlightFooter = (props: BookFlightFooterProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const price = useSelector(
      (state: AppState) => state.selectedFlights.pricePreview
  );

  // Accessing the passenger counts from the home slice
  const adults = useSelector((state: AppState) => state.home.adults);
  const children = useSelector((state: AppState) => state.home.children);
  const infants = useSelector((state: AppState) => state.home.infants);

  const newSearch = () => {
    dispatch(resetHomeForm());
    dispatch(clearPassengers());
    dispatch(clearFlights());
    history.push("/");
  };

  const PassengerTitle: any = {
    [PassengerType.ADULT]: t("app.bookFlight.passengers.passengerRow.adult", "Adult"),
    [PassengerType.CHILD]: t("app.bookFlight.passengers.passengerRow.child", "Child"),
    [PassengerType.INFANT]: t("app.bookFlight.passengers.passengerRow.baby", "Infant")
  }

  const totalPrice = props.totalPrice ? props.totalPrice : price;

  return (
      <div className="nav-bar">
        <div className="grandPrice">
          <p>{t("app.prompt.totalPayment", "Total Payment: ")} </p>
          <div className="navBarItem">
            {adults > 0 ? (
                <>
                  <img src={Person} />
                  <p>{adults} {PassengerTitle[PassengerType.ADULT]}</p>
                </>
            ) : null}
            {children > 0 ? (
                <>
                  <img src={Person} />
                  <p>{children} {PassengerTitle[PassengerType.CHILD]}</p>
                </>
            ) : null}
            {infants > 0 ? (
                <>
                  <img src={Person} />
                  <p>{infants} {PassengerTitle[PassengerType.INFANT]}</p>
                </>
            ) : null}
          </div>
          <h1>{totalPrice}</h1>
        </div>

        <div className="nav-buttons">
          {!props.newSearchBtnHide && (
              <Button
                  className="button-text-primary mr-0"
                  htmlType="submit"
                  onClick={newSearch}
              >
                {t("app.bookFlight.footer.newSearch", "New search")}
              </Button>
          )}
          {!props.hideContinue && (
              <Button
                  className="button-primary"
                  htmlType="button"
                  disabled={props.disableContinue}
                  onClick={props.continue}
                  style={{ marginLeft: 'auto' }}
              >
                {t("app.bookFlight.footer.continue", "Continue")}
              </Button>
          )}
        </div>
      </div>
  );
};

export default BookFlightFooter;