import React, {useEffect, useState} from "react";
import styles from './Details.module.scss';
import AirplaneIcon from "../../assets/images/airplane.svg";
import QrCode from "../../assets/images/qr-code.png";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface Props {
    traveler: any;
}

const Details = ({traveler}: Props) => {
    const {t} = useTranslation();

    const { i18n } = useTranslation();
    const [locale, setLocale] = useState(i18n.language);

    useEffect(() => {
        moment.locale(i18n.language);
        setLocale(i18n.language);
    }, [i18n.language]);

    const formatFlightDuration = (duration:any) => {
        const [hours, minutes] = duration.split(' ');
        const hoursValue = hours.replace('h', '');
        const minutesValue = minutes ? minutes.replace('min', '') : '0';

        if (parseInt(minutesValue) === 0) {
            return `${hoursValue}h`;
        }

        return `${hoursValue}h ${minutesValue}min`;
    };

    return (
        <div className={styles.detailsContainer}>
            <div className={styles.detailsContainerCard}>
                <div className={styles.header}>
                    {traveler.departureFlight.departureAirportCode}
                    <img src={AirplaneIcon}/>
                    {traveler.departureFlight.arrivalAirportCode}
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.bookFlight.overview.bookingInformation.name", "EMRI")}</div>
                        <div className={styles.columnValue}>{traveler.name}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.bookFlight.overview.bookingInformation.surname", "MBIEMRI")}</div>
                        <div className={styles.columnValue}>{traveler.surname}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.bookFlight.flights.flightPreview.departure.title", "Nisja")}</div>
                        <div className={styles.columnValue}>{moment(traveler.departureFlight.timeOfDeparture, "HH:mm").format("HH:mm")}</div>
                        <div className={styles.columnHeader}>{moment(traveler.departureFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, MMMM D")}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.bookFlight.flights.flightPreview.arrival.title", "Mbërritja")}</div>
                        <div className={styles.columnValue}>{moment(traveler.departureFlight.timeOfArrival, "HH:mm").format("HH:mm")}</div>
                        <div className={styles.columnHeader}>{moment(traveler.departureFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, MMMM D")}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.flight.duration" , "Kohëzgjatja")}</div>
                        <div className={styles.columnValue}>{formatFlightDuration(traveler.departureFlight.duration)}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    {/*<div className={styles.col}>*/}
                    {/*    <div className={styles.columnHeader}>Flight No.</div>*/}
                    {/*    <div className={styles.columnValue}>{traveler.departureFlight.flightNumber}</div>*/}
                    {/*</div>*/}
                    {/*<div className={styles.col}>*/}
                    {/*    <div className={styles.columnHeader}>Gate</div>*/}
                    {/*    <div className={styles.columnValue}>{traveler.departureFlight.gate}</div>*/}
                    {/*</div>*/}
                </div>

                {/*<div className={styles.qrCode}>*/}
                {/*    <img src={QrCode} alt="qr-code"/>*/}
                {/*    <div>*/}
                {/*        <b>Flight QR Code</b>*/}
                {/*        <div>Tap to enlarge</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            {traveler.returningFlight && <div className={styles.detailsContainerCard}>
                <div className={styles.header}>
                    {traveler.returningFlight.departureAirportCode}
                    <img src={AirplaneIcon}/>
                    {traveler.returningFlight.arrivalAirportCode}
                </div>
                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.bookFlight.overview.bookingInformation.name", "EMRI")}</div>
                        <div className={styles.columnValue}>{traveler.name}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.bookFlight.overview.bookingInformation.surname", "MBIEMRI")}</div>
                        <div className={styles.columnValue}>{traveler.surname}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.bookFlight.flights.flightPreview.departure.title", "Nisja")}</div>
                        <div className={styles.columnValue}>{moment(traveler.returningFlight.timeOfDeparture, "HH:mm").format("HH:mm")}</div>
                        <div className={styles.columnHeader}>{moment(traveler.returningFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, MMMM D")}</div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.bookFlight.flights.flightPreview.arrival.title", "Mbërritja")}</div>
                        <div className={styles.columnValue}>{moment(traveler.returningFlight.timeOfArrival, "HH:mm").format("HH:mm")}</div>
                        <div className={styles.columnHeader}>{moment(traveler.returningFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, MMMM D")}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={styles.col}>
                        <div className={styles.columnHeader}>{t("app.flight.duration" , "Kohëzgjatja")}</div>
                        <div className={styles.columnValue}>{formatFlightDuration(traveler.returningFlight.duration)}</div>
                    </div>
                </div>

                <div className={styles.row}>
                    {/*<div className={styles.col}>*/}
                    {/*    <div className={styles.columnHeader}>Flight No.</div>*/}
                    {/*    <div className={styles.columnValue}>{traveler.returningFlight.flightNumber}</div>*/}
                    {/*</div>*/}
                    {/*<div className={styles.col}>*/}
                    {/*    <div className={styles.columnHeader}>Gate</div>*/}
                    {/*    <div className={styles.columnValue}>{traveler.returningFlight.gate}</div>*/}
                    {/*</div>*/}
                </div>

                {/*<div className={styles.qrCode}>*/}
                {/*    <img src={QrCode} alt="qr-code"/>*/}
                {/*    <div>*/}
                {/*        <b>Flight QR Code</b>*/}
                {/*        <div>Tap to enlarge</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>}
        </div>
    )
}

export default Details;
