import React from "react";
import styles from './about.module.scss';
import Footer from "../../components/Footer/Footer";
import AboutUsImage from "../../assets/images/about-img.png"
import Star from "../../assets/images/Group 2.svg";
import Seat from "../../assets/images/Group 4.svg";
import Ticket from "../../assets/images/Group 3.svg";
import Headphones from "../../assets/images/Group 5.svg"

import {useTranslation} from "react-i18next";

const About = () => {
  const {t} = useTranslation();
  return (
      <div>
        <div className={styles.aboutUsContent}>
          <img src={AboutUsImage}/>
          <div className={styles.aboutUsAlignment}>
            <h1><b>{t("app.aboutUs.content.whoWeAre", "Rreth nesh")}</b></h1>
            <p>{t("app.aboutUs.content", "Airtiketa L.L.C” si kompani u themelua në vitin 2015 dhe menaxhohet nga stafi me përvojë mbi 20 vjet. Duke menduar gjithmonë që t'i shpërndajmë kilometrat që i ndajnë bashkatdhetarët tanë nga shtëpia e tyre, prioriteti ynë si kompani është të sjellim më të mirën te klientët tanë. Përveç sigurisë, komoditetit dhe çmimeve më të mira në treg, kompania jonë është dëshmuar edhe për korrektësinë ndaj të gjithë atyre që kanë besuar tek ne. Të ofrosh më të mirën duhet fillimisht të jesh ndër më të mirët, dhe kjo sigurisht falë përkushtimit të stafit profesional që është në dispozicion 24/7, duke qenë aty për ju në çdo kohë! Për qasje më të lehtë, ne kemi krijuar sistemin tonë online të rezervimit për t'iu kursyer kohë dhe për t'i lehtësuar procedurat. Eja në shtëpinë tënde! Më sigurt dhe më lirë se kurrë më parë! Lërini ëndrrat të udhëtojnë:")}</p>
            <div className={styles.aboutUsBenefits}>
              <h2>{t("app.benefits.title" , "Blerje e Sigurt dhe e Shpejtë Online me AirTiketa.com")}</h2>
              <div className={styles.benefits}>
                <div className={styles.benefitsRow}>
                  <img src={Star}/>
                  <div className={styles.benefitsText}>
                    <h3>{t("app.benefits.subTitle1" , "Fluturo Lirë dhe 100% Sigurtë")}</h3>
                    <p>{t("app.benefits.paragraph1" , "Rezervoni Fluturimin Tuaj Online - Pagesë të Sigurt!")}</p>
                  </div>
                </div>
                <div className={styles.benefitsRow}>
                  <img src={Seat}/>
                  <div className={styles.benefitsText}>
                    <h3>{t("app.benefits.subTitle2" , "Zgjedhja e Ulëses Falas")}</h3>
                    <p>{t("app.benefits.paragraph2" , "Zgjidhni ulëset pa kosto shtesë dhe udhëtoni rehat!")}</p>
                  </div>
                </div>
                <div className={styles.benefitsRow}>
                  <img src={Ticket}/>
                  <div className={styles.benefitsText}>
                    <h3>{t("app.benefits.subTitle3" , "Check-in Falas në Aeroport")}</h3>
                    <p>{t("app.benefits.paragraph3" , "Check-in falas në aeroport - udhëtoni pa pengesa!")}</p>
                  </div>
                </div>
                <div className={styles.benefitsRow}>
                  <img src={Headphones}/>
                  <div className={styles.benefitsText}>
                    <h3>{t("app.benefits.subTitle4" , "Mbështetje në Çdo Moment për Udhëtarët Tanë")}</h3>
                    <p>{t("app.benefits.paragraph4" , "Qendra jonë e kontaktit: 24/7 në Gjermani, Zvicër, dhe Kosovë.")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
  )
}

export default About;
