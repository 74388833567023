import React, {useEffect, useState} from 'react';
import "../overview.scss"
import {useSelector} from "react-redux";
import {AppState} from "../../../../store";
import {useTranslation} from "react-i18next";
import FlightsService from "../../../../actions/flights.service";
import Passenger from "../../../../components/Passenger/Passenger";
import {Divider} from "antd";
import {PassengerType} from "../../../../components/PassengerFormRow/PassengerFormRow";
import moment from "moment/moment";

interface Props {
  totalPrice?: string | number;
}

const BookingInformation = (props : Props) => {
  const passengersPsg = useSelector((state: AppState) => state.passengers.passengers);
  const selectedDepartureFlight = useSelector((state: AppState) => state.selectedFlights.departureFlight);
  const selectedReturnFlight = useSelector((state: AppState) => state.selectedFlights.returnFlight);
  const {t} = useTranslation();

  const price = useSelector(
      (state: AppState) => state.selectedFlights.pricePreview
  );

  const totalPrice = props.totalPrice ? props.totalPrice : price;


  const [flight, setFlight] = useState<any>(null);
  // const [returnFlight, setReturnFlight] = useState<any>({});

  useEffect(() => {
    FlightsService.getFlightById(selectedDepartureFlight.id).then(res => {
      setFlight(res);
    });
  }, []);


  return (
      <div>
        {passengersPsg.map((passenger: any) =>
            <Passenger passenger={passenger}/>
        )}
        <div className="information">
          {flight && ((Object.keys(selectedReturnFlight).length !== 0) ?
              <div className="flight-info">
                <div style={{display: "flex"}}>
                  <div style={{paddingRight: '45px', borderRight: "1px solid #fff", borderRightStyle: "dashed"}}>
                    <p>{t("app.home.departure", "Departure")}</p>
                    <h3>{flight.departureAirport.location}, {flight.departureAirport.code} - {flight.arrivalAirport.location}, {flight.arrivalAirport.code}</h3>
                    <p>{moment(flight.dateOfDeparture, "DD/MM/YYYY").format("dddd, DD MMMM")}</p>
                    <p>{t("app.bookFlight.flights.flightPreview.departureTime", "Koha e nisjes: ")}{flight.timeOfDeparture}</p>
                  </div>
                  <div>
                  </div>

                  <div style={{padding: '0 45px'}}>
                    <p>{t("app.home.return", "Return")}</p>
                    <h3>{flight.arrivalAirport.location}, {flight.arrivalAirport.code} - {flight.departureAirport.location}, {flight.departureAirport.code}</h3>
                    <p>{moment(selectedReturnFlight.dateOfDeparture, "DD/MM/YYYY").format("dddd, DD MMMM")}</p>
                    <p>{t("app.bookFlight.flights.flightPreview.departureTime", "Koha e nisjes: ")} {selectedReturnFlight.timeOfDeparture}</p>
                  </div>
                </div>
                <div style={{paddingTop: "35px"}}><p>{t("app.prompt.totalPayment", "Pagesa totale :")}</p><h3 style={{float: "right"}}>{totalPrice}</h3></div>
              </div>
              :
              <div className="flight-info-oneWay">
                <div>
                  <p>{moment(flight.dateOfDeparture, "DD/MM/YYYY").format("dddd, DD MMMM")} - {t("app.home.form.return.oneway", "ONE WAY")}</p>
                  <h3>{flight.departureAirport.location}, {flight.departureAirport.code} - {flight.arrivalAirport.location}, {flight.arrivalAirport.code}</h3>
                  <p>{t("app.bookFlight.flights.flightPreview.departureTime", "Koha e nisjes: ")} {flight.timeOfDeparture}</p>
                </div>
                <div style={{paddingTop: "35px"}}><p>{t("app.prompt.totalPayment", "Pagesa totale :")}</p><h3 style={{float: "right"}}>{totalPrice}</h3></div>
              </div>)}
        </div>
      </div>

  )
}

export default BookingInformation;
