import React, {useEffect, useState} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import './App.scss';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from './store';
import Homepage from "./containers/Home/Home";
import BookFlight from "./containers/BookFlight/BookFlight";
import Bookings from "./containers/Bookings/Bookings";
import BookingDetails from "./containers/BookingDetails/BookingDetails";
import About from "./containers/About/About";
import Contact from "./containers/Contact/Contact";
import Header from "./components/Header/Header";
import {useHistory, useLocation} from "react-router";
import Result from "./containers/Result";
import AuthRoute from "./components/Routes/AuthRoute";
import {fetchOffers} from "./store/offers";
import TermsAndConditions from "./containers/Terms&Conditions/TermsAndConditions";
import ReactGa from "react-ga";
import {getQueryParameterByName} from "./utils";
import {possibleLanguages} from "./i18n";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import DocumentMeta from "./components/DocumentMeta/DocumentMeta";
import { Spin } from 'antd';
import Information from "./containers/Information/Information";

// @ts-ignore
ReactGa.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS || 'UA-50424363-2');

function App() {
    const {i18n} = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const isDrawerOpen = useSelector((state: AppState) => state.auth.isDrawerOpen);
    const accessToken = useSelector((state: AppState) => state.auth.accessToken);

    const language = getQueryParameterByName("language");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const Loader = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spin size="large" />
        </div>
    );

    useEffect(() => {
        ReactGa.pageview(window.location.pathname + window.location.search);
        dispatch(fetchOffers());

        if (window.location.pathname.startsWith('/booking/')) {
            setLoaderVisible(true);
            document.body.style.overflow = 'hidden';
            setTimeout(() => {
                const redirectUrl =
                    process.env.NODE_ENV === 'development'
                        ? 'https://airtiketa.dev.bemyticket.com'
                        : 'https://fly.airtiketa.eu';
                window.location.replace(redirectUrl);
            }, 1000);
        }
        const getLanguage = localStorage.getItem('language')
        if (getLanguage) {
            i18n.changeLanguage(getLanguage)
            localStorage.setItem('language', getLanguage);
            localStorage.setItem('i18nextLng', getLanguage);
        } else {
            localStorage.setItem('language', i18n.language);
            localStorage.setItem('i18nextLng', i18n.language);
        }

        if(language && possibleLanguages.includes(language)) {
            onLanguageChange(language);
        }
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, [history.location.pathname])

    const onLanguageChange = (lang: any) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
        localStorage.setItem('i18nextLng', lang);
    }

    return (
        <div>
            {loaderVisible ? <Loader /> :
                (<div><DocumentMeta/>
            <Header/>
            {/*<AuthenticationDrawer isDrawerOpen={isDrawerOpen} onClose={() => {*/}
            {/*    dispatch(setLoginDrawer(false))*/}
            {/*    dispatch(setDrawerContent('login'));*/}

            {/*}} />*/}
            <Switch>
                <Route exact path="/" component={Homepage}/>
                <Route path="/booking" component={BookFlight}/>

                <Route path="/handlers/payments" component={Result}/>

                {/*<AuthRoute exact path="/bookings" component={Bookings} authenticated={!!accessToken} />*/}
                <Route path="/bookings/:reservationId" component={BookingDetails} />

                {/*<AuthRoute exact path="/account" component={Account} authenticated={!!accessToken} />*/}
                {/*<AuthRoute path="/account/edit" component={EditAccount} authenticated={!!accessToken} />*/}
                {/*<Route path="/account/reset-password/:key" component={ResetAccountPassword} />*/}

                <Route path="/about" component={About}/>
                <Route path="/contact" component={Contact}/>
                <Route path="/information" component={Information}/>

                <Route path="/terms&conditions" component={TermsAndConditions}/>

                <Route path="/privacy-policy" component={PrivacyPolicy} />

            </Switch></div>)}

        </div>

    );
}


export default withRouter(App);
